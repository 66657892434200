<template>
  <div v-if="!isMobile()">
    <div class="w-300px pl-4 pb-4">
      <v-combobox
        :items="countries"
        :label="$t('commonTable.country')"
        item-text="name"
        item-value="id"
        v-model="selectedCountry"
        @change="onChangeCountry"
        outline
        clearable
      ></v-combobox>
    </div>
    <v-data-table
      :headers="headers"
      :items="users"
      :options.sync="pagination"
      :server-items-length="totalUsers"
      :loading="loading"
      :footer-props="{
        showFirstLastPage: true,
        'items-per-page-options': [10, 20, 50, 100]
      }"
      class="elevation-1"
    >
      <template v-slot:item.actions="{ item }">
        <v-menu>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
          </template>
          <v-list>
            <v-dialog
              v-model="item.passwordDialog"
              persistent
              max-width="400px"
              :click-outside-to-close="true"
            >
              <template v-slot:activator="{ on }">
                <v-list-item @click="showPasswordDialog(item)" v-on="on">
                  <v-icon class="pr-2">mdi-pencil-outline</v-icon>
                  Change Password
                </v-list-item>
              </template>
              <v-card class="mx-auto" max-width="400px">
                <v-card-title class="text-center">
                  <span class="headline"
                    >Change Password for: <br />
                    {{ item.email }}</span
                  >
                </v-card-title>
                <v-card-text>
                  <v-text-field
                    v-model="item.newPassword"
                    label="New Password"
                    :append-icon="passwordMask ? 'mdi-eye-off' : 'mdi-eye'"
                    @click:append="togglePasswordMask"
                    :type="passwordMask ? 'password' : 'text'"
                    :error-messages="passwordDialogErrors"
                  ></v-text-field>
                </v-card-text>
                <v-card-actions>
                  <v-btn text @click="hidePasswordDialog(item)">Cancel</v-btn>
                  <v-btn text :loading="item.isSubmitting" @click="submitPassword(item)">
                    Ok
                  </v-btn>
                </v-card-actions>
                <v-progress-linear
                  v-if="item.isSubmitting"
                  color="primary"
                  indeterminate
                  class="my-4"
                ></v-progress-linear>
              </v-card>
            </v-dialog>
            <router-link
              :to="{
                name: 'UserShops',
                params: {
                  userId: item.id,
                  userName:
                    item.userProfiles != null && item.userProfiles.length > 0
                      ? item.userProfiles[0].fullName
                      : item
                }
              }"
            >
              <v-list-item>
                <v-icon class="pr-2">mdi-cart</v-icon>
                Edit shop
              </v-list-item>
              <!--                  <v-avatar
                      v-if="item.shopList.length > 0"
                      color="amber darken-4"
                      size="28"
                      rounded
                    >
                      <v-icon dark>mdi-cart</v-icon>
                    </v-avatar>-->
            </router-link>
            <v-list-item v-if="currentUser.superAdmin" @click="openChangeRoleModal(item)">
              <v-icon class="pr-2">mdi-account</v-icon>
              Change Roles
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      <template v-slot:item.username="{ item }">
        <td class="text-xs-right">
          {{
            item.userProfiles != null && item.userProfiles.length > 0
              ? item.userProfiles[0].fullName
              : ""
          }}
        </td>
      </template>
      <!--      <template v-slot:item.gender="{ item }">
        <td class="text-xs-right">
          <v-select
            v-if="item.userProfiles != null && item.userProfiles.length > 0"
            v-model="item.userProfiles[0].gender"
            :items="genders"
            item-text="text"
            item-value="value"
            return-object
            single-line
            v-on:change="changeGender(item.userProfiles[0])"
            :disabled="loading"
          ></v-select>
        </td>
      </template>-->
      <template v-slot:item.categoryOverride="{ item }">
        <v-switch
          inset
          color="#4CAF50"
          v-model="item.categoryOverride"
          @click="toggleUserCategory(item.id, item.categoryOverride)"
        ></v-switch>
      </template>
      <template v-slot:item.category="{ item }">
        <td class="text-xs-right">
          <v-select
            :items="categories"
            v-model="item.categoryId"
            class="type"
            item-text="name"
            item-value="id"
            single-line
            return-object
            :disabled="loading"
            v-on:change="changeCategory(item.id, item.categoryId)"
          >
          </v-select>
        </td>
      </template>
      <template v-slot:item.city="{ item }">
        <td class="text-xs-right">
          {{
            item.userProfiles != null &&
            item.userProfiles.length > 0 &&
            item.userProfiles[0].city != null
              ? item.userProfiles[0].city.name
              : ""
          }}
        </td>
      </template>
      <template v-slot:item.shopList="{ item }">
        {{ item.shopList.length > 0 ? item.shopList[0].name : "" }}
        <v-menu bottom offset-y>
          <template v-slot:activator="{ on }">
            <v-icon v-if="item.shopList.length > 1" small class="mr-2" v-on="on"
              >mdi-dots-horizontal
            </v-icon>
          </template>
          <v-list dense style="max-height: 500px" class="overflow-y-auto">
            <v-list-item v-for="(item, i) in item.shopList" :key="i">
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      <template v-slot:item.orderCount="{ item }">
        {{ item.orderCount }}
      </template>
      <template v-slot:item.activated="{ item }">
        <v-switch
          inset
          color="#4CAF50"
          v-model="item.activated"
          @click="activateUser(item.id, item.activated)"
        ></v-switch>
      </template>
      <template v-slot:item.suspended="{ item }">
        <v-switch
          inset
          color="#4CAF50"
          v-model="item.suspended"
          @click="suspendUser(item.id, item.suspended)"
        ></v-switch>
      </template>

      <template v-slot:item.vip="{ item }">
        <v-switch
          inset
          color="#4CAF50"
          v-model="item.vipMember"
          @click="toggleVip(item.id, item.vipMember)"
        ></v-switch>
      </template>

      <template v-slot:item.oobleeAdmin="{ item }">
        <v-switch
          inset
          color="#4CAF50"
          v-model="item.oobleeAdmin"
          @click="toggleOobleeAdmin(item.id, item.oobleeAdmin)"
        ></v-switch>
      </template>

      <template v-slot:item.loyal="{ item }">
        <v-switch
          inset
          color="#4CAF50"
          v-model="item.loyaltyMember"
          @click="toggleLoyal(item.id, item.loyaltyMember)"
        ></v-switch>
      </template>

      <template v-slot:item.influencer="{ item }">
        <v-switch
          inset
          color="#4CAF50"
          v-model="item.influencer"
          @click="toggleUserInfluencer(item.id, item.influencer)"
        ></v-switch>
      </template>

      <template v-slot:item.profile="{ item }">
        <v-avatar color="indigo" size="24">
          <v-icon dark @click="goToProfileDialog(item.id)">mdi-account-circle </v-icon>
        </v-avatar>
      </template>
      <template v-slot:body.prepend>
        <tr>
          <td>
            <v-text-field
              label="ID"
              v-model="userId"
              type="text"
              @keyup.enter="
                page = 1;
                retrieveUsers();
              "
            ></v-text-field>
          </td>

          <td>
            <v-text-field
              label="Name"
              v-model="userName"
              type="text"
              @keyup.enter="
                page = 1;
                retrieveUsers();
              "
            ></v-text-field>
          </td>

          <td>
            <v-text-field
              label="Email"
              v-model="email"
              type="text"
              @keyup.enter="
                page = 1;
                retrieveUsers();
              "
            ></v-text-field>
          </td>

          <td colspan="2">
            <v-select
              label="Category"
              v-model="category"
              dense
              hide-details
              :items="categories"
              item-text="name"
              item-value="id"
              @keyup.enter="
                page = 1;
                retrieveUsers();
              "
            ></v-select>
          </td>

          <td>
            <v-datetime-picker
              :label="$t('date.from')"
              v-model="dateFrom"
              time-format="HH:mm:ss"
            ></v-datetime-picker>
            <v-datetime-picker
              :label="$t('date.to')"
              v-model="dateTo"
              time-format="HH:mm:ss"
            ></v-datetime-picker>
          </td>

          <td>
            <v-autocomplete
              v-model="city"
              :items="userCities"
              :loading="citiesIsLoading"
              :search-input.sync="search"
              clearable
              item-text="name"
              item-value="id"
              label="City"
              @keyup.enter="
                page = 1;
                retrieveUsers();
              "
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title>
                    Search for
                    <strong>cities</strong>
                  </v-list-item-title>
                </v-list-item>
              </template>
              <template v-slot:item="data">
                <template v-if="typeof data.item !== 'object'">
                  <v-list-item-content v-text="data.item"></v-list-item-content>
                </template>
                <template v-else>
                  <v-list-item-content>
                    <v-list-item-title v-html="data.item.name"></v-list-item-title>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
          </td>

          <td>
            <v-select
              label="Activated"
              :items="userActivatedList"
              v-model="userActivated"
              dense
              hide-details
              @keyup.enter="
                page = 1;
                retrieveUsers();
              "
            ></v-select>
          </td>

          <td>
            <v-select
              label="Suspended"
              :items="userSuspendedList"
              v-model="userSuspended"
              dense
              hide-details
              @keyup.enter="
                page = 1;
                retrieveUsers();
              "
            ></v-select>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-select
              label="Affiliate"
              :items="userInfluencerList"
              v-model="userInfluencer"
              dense
              hide-details
              @keyup.enter="
                page = 1;
                retrieveUsers();
              "
            ></v-select>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
            <v-text-field
              label="Shop"
              v-model="shopName"
              type="text"
              @keyup.enter="
                page = 1;
                retrieveUsers();
              "
            ></v-text-field>
          </td>

          <td>
            <v-avatar color="indigo" size="30">
              <v-icon
                dark
                @click="
                  page = 1;
                  retrieveUsers();
                "
                >mdi-magnify
              </v-icon>
            </v-avatar>
            <v-spacer class="pt-2 pb-2"></v-spacer>
            <v-avatar color="grey" size="30">
              <v-icon
                dark
                @click="
                  page = 1;
                  clearSearch();
                "
                >mdi-close
              </v-icon>
            </v-avatar>
          </td>
          <!--          <td>
            <v-avatar color="grey" size="30">
              <v-icon
                  dark
                  @click="
                  page = 1;
                  clearSearch();
                "
              >mdi-close
              </v-icon>
            </v-avatar>
          </td>-->
        </tr>
      </template>
    </v-data-table>

    <div class="table-footer-prepend d-flex pl-2 align-center">
      <v-btn
        :loading="downloadingReport"
        :disabled="downloadingReport"
        color="green darken-1"
        class="ma-2 white--text"
        @click="
          loader = 'downloadingReport';
          downloadReport();
        "
      >
        Users Report
        <v-icon right dark>
          mdi-file-excel
        </v-icon>
      </v-btn>
    </div>
    <ProfileDialog
      v-model="showProfileDialog"
      @close="showProfileDialog = false"
      :userProfile="userProfile"
    />
    <ChangeRoleDialog ref="changeRoleDialog" v-if="currentUser.superAdmin" />
  </div>
  <div v-else-if="isMobile()">
    <v-data-table
      :headers="headersMobile"
      :items="users"
      :options.sync="pagination"
      :server-items-length="totalUsers"
      :loading="loading"
      :footer-props="{
        showFirstLastPage: true,
        'items-per-page-options': [10, 20, 50, 100]
      }"
      class="elevation-1"
    >
      <template v-slot:item.username="{ item }">
        <td class="text-xs-right">
          {{
            item.userProfiles != null && item.userProfiles.length > 0
              ? item.userProfiles[0].fullName
              : ""
          }}
        </td>
      </template>
      <template v-slot:item.city="{ item }">
        <td class="text-xs-right">
          {{
            item.userProfiles != null && item.userProfiles[0].city != null
              ? item.userProfiles[0].city.name
              : ""
          }}
        </td>
      </template>
      <template v-slot:item.shopList="{ item }">
        {{ item.shopList.length > 0 ? item.shopList[0].name : "" }}
        <v-menu bottom offset-y>
          <template v-slot:activator="{ on }">
            <v-icon v-if="item.shopList.length > 1" small class="mr-2" v-on="on"
              >mdi-dots-horizontal
            </v-icon>
          </template>
          <v-list dense style="max-height: 500px" class="overflow-y-auto">
            <v-list-item v-for="(item, i) in item.shopList" :key="i">
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:item.activated="{ item }">
        <v-switch
          v-model="item.activated"
          @click="activateUser(item.id, item.activated)"
        ></v-switch>
      </template>
      <template v-slot:item.profile="{ item }">
        <v-avatar color="indigo" size="20">
          <v-icon dark @click="goToProfileDialog(item.id)">mdi-account-circle </v-icon>
        </v-avatar>
      </template>
      <template v-slot:body.prepend>
        <div class="filter">
          <div>
            <v-text-field v-model="userName" type="text" label="username"></v-text-field>
          </div>
          <div>
            <v-text-field v-model="email" type="text" label="email"></v-text-field>
          </div>
          <div>
            <v-select
              :items="userActivatedList"
              v-model="userActivated"
              dense
              hide-details
              label="activation status"
              aria-label="activated"
            ></v-select>
          </div>
          <div class="search">
            <v-row>
              <v-col
                style="padding-right: 0px; padding-left: 0px; max-width: 24px; margin-right: 10px;"
              >
                <v-avatar color="indigo" size="30">
                  <v-icon
                    dark
                    @click="
                      page = 1;
                      retrieveUsers();
                    "
                    >mdi-magnify
                  </v-icon>
                </v-avatar>
              </v-col>
              <v-col
                style="padding-right: 0px; padding-left: 0px; max-width: 24px; margin-right: 10px;"
              >
                <v-avatar color="grey" size="24">
                  <v-icon
                    dark
                    @click="
                      page = 1;
                      clearSearch();
                    "
                    >mdi-close
                  </v-icon>
                </v-avatar>
              </v-col>
            </v-row>
          </div>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import UserApiService from "@/core/services/api.service.user";
import CountryService from "@/core/services/country.service";
import ProfileDialog from "@/view/pages/ProfileDialog";
import ChangeRoleDialog from "@/view/layout/extras/dialogs/change-role-dialog";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  name: "users",
  computed: {
    name() {
      return this.$i18n.t("menu.users");
    },
    headersMobile() {
      return [
        {
          text: "Id",
          align: "start",
          value: "id",
          width: "50%"
        },
        { text: this.$i18n.t("common.user"), value: "username", sortable: false },
        { text: this.$i18n.t("common.email"), value: "email", sortable: false },
        {
          text: this.$i18n.t("common.activated"),
          value: "activated",
          width: "10%",
          sortable: false
        }
      ];
    },
    headers() {
      return [
        { text: "ID", align: "center", value: "id", width: "5%", sortable: false },
        { text: "Name", align: "center", value: "username", width: "10%", sortable: false },
        {
          text: this.$i18n.t("common.email"),
          align: "start",
          value: "email",
          sortable: false,
          width: "10%"
        },
        {
          text: "Forced",
          value: "categoryOverride",
          width: "3%",
          align: "center",
          sortable: false
        },
        {
          text: "User category",
          value: "category",
          width: "5%",
          align: "center",
          sortable: false
        },
        {
          text: this.$i18n.t("pages.users.activationDate"),
          value: "activationDate",
          align: "center",
          width: "10%"
        },
        {
          text: this.$i18n.t("commonTable.city"),
          value: "city",
          width: "10%",
          sortable: false,
          align: "center"
        },
        {
          text: this.$i18n.t("common.activated"),
          value: "activated",
          width: "5%",
          sortable: false
        },
        {
          text: this.$i18n.t("pages.users.suspended"),
          value: "suspended",
          width: "5%",
          sortable: false
        },
        {
          text: "Num of orders",
          value: "orderCount",
          width: "5%",
          sortable: false
        },
        {
          text: "LOYAL",
          value: "loyal",
          width: "5%",
          sortable: false
        },
        {
          text: "AFFILIATE",
          value: "influencer",
          width: "5%",
          sortable: false
        },
        {
          text: "VIP",
          value: "vip",
          width: "5%",
          sortable: false
        },
        {
          text: "ADMIN",
          value: "oobleeAdmin",
          width: "5%",
          sortable: false
        },
        {
          text: this.$i18n.t("common.shop"),
          value: "shopList",
          width: "15%",
          sortable: false,
          align: "center"
        },
        { text: "", value: "actions", sortable: false, width: "1%" }
        /*        { text: "Shops", value: "shops", width: "1%", sortable: false },
        { text: "", value: "setPassword", width: "1%", sortable: false }*/
      ];
    },
    gendersFilter() {
      return [
        {
          text: this.$i18n.t("gender.all"),
          value: "All"
        },
        {
          text: this.$i18n.t("pages.users.profile"),
          value: "profile",
          width: "5%",
          sortable: false
        }
      ];
    },
    genders() {
      return [
        {
          text: this.$i18n.t("gender.male"),
          value: "M"
        },
        {
          text: this.$i18n.t("gender.female"),
          value: "F"
        },
        {
          text: this.$i18n.t("gender.unisex"),
          value: "U"
        }
      ];
    },
    userActivatedList() {
      return [this.$i18n.t("common.all"), this.$i18n.t("common.no"), this.$i18n.t("common.yes")];
    },
    userInfluencerList() {
      return [this.$i18n.t("common.all"), this.$i18n.t("common.no"), this.$i18n.t("common.yes")];
    },
    userSuspendedList() {
      return [
        this.$i18n.t("common.all"),
        this.$i18n.t("common.notSuspended"),
        this.$i18n.t("common.suspended")
      ];
    },
    ...mapGetters(["currentUser"])
  },
  data() {
    return {
      passwordMask: true,
      passwordDialogErrors: [],
      gender: "",
      category: "",
      categoryChanging: false,
      genderChanging: false,
      pageSizes: [10, 20, 50, 100],
      userName: "",
      userId: "",
      email: "",
      dateFrom: null,
      dateTo: null,
      dates: [],
      dateRangeMenu: false,
      city: "",
      userCities: [],
      citiesIsLoading: false,
      shopName: "",
      search: "",
      userInfluencer: "",
      userActivated: "",
      userSuspended: "",
      userRole: "",
      totalUsers: 0,
      users: [],
      loading: false,
      pagination: {},
      dialogTest: false,
      showProfileDialog: false,
      userProfile: null,
      notifications: false,
      sound: true,
      widgets: false,
      downloadingReport: false,
      loader: null,
      userRoleList: ["All", "Member", "Administrator", "Superadmin", "Partner"],
      errors: [],
      categories: [],
      categoriesLoading: false,
      countries: [],
      selectedCountry: null
    };
  },
  components: {
    ProfileDialog,
    ChangeRoleDialog
  },
  watch: {
    pagination: {
      handler() {
        this.retrieveUsers();
      },
      deep: true
    }
  },
  async mounted() {
    await UserApiService.init();
    await CountryService.init();
    await this.$store.dispatch(SET_BREADCRUMB, [{ title: this.name }]);
    await this.getCategories();
    this.getCountries();
  },
  created() {
    console.log("async created");
    this.searchCities();
  },
  methods: {
    togglePasswordMask() {
      console.log("Change password mask from " + this.passwordMask);
      this.passwordMask = !this.passwordMask;
    },

    showPasswordDialog(user) {
      this.passwordDialogErrors = [];
      console.log("Show set password dialog for " + user.email);
      this.passwordMask = true;
      //user.passwordDialog = true;
      user.newPassword = "";
    },
    hidePasswordDialog(user) {
      console.log("Hide set password dialog for" + user.email);
      user.passwordDialog = false;
    },
    submitPassword(user) {
      this.passwordDialogErrors = [];
      console.log("Submit new password for " + user.email);
      if (user.newPassword === "") {
        this.passwordDialogErrors = ["Password is required."];
        return;
      }

      let setPasswordRequest = {};
      setPasswordRequest["email"] = user.email;
      setPasswordRequest["password"] = user.newPassword;
      this.loading = true;
      UserApiService.post(`/v4/set-password`, setPasswordRequest)
        .then(response => {
          this.$log.debug("setPassword success: " + response);
          user.passwordDialog = false;
        })
        .catch(error => {
          console.log("submitPassword error: " + error.message);
          this.passwordDialogErrors = [error.message];
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getCountries() {
      this.loading = true;
      CountryService.getCountries()
        .then(res => {
          console.log("COUNTRIES RESPONSE", res);
          if (!res?.length) return;
          this.countries = res;
          const activeCountryId = CountryService.getActiveCountry();
          this.selectedCountry = this.countries.find(item => item.id === activeCountryId);
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },

    async onChangeCountry() {
      await this.retrieveUsers();
      this.searchCities();
    },

    downloadReport() {
      this.downloadingReport = true;
      const params = this.getRequestParams({
        userName: this.userName,
        category: this.category,
        email: this.email,
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        city: this.city,
        userInfluencer: this.userInfluencer,
        userActivated: this.userActivated,
        userSuspended: this.userSuspended,
        shopName: this.shopName,
        userId: this.userId
      });

      params.responseType = "blob";
      ApiService.getFileWithParams(`/api/download/excel/users-report`, params)
        .then(response => {
          const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "Users";
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.downloadingReport = false));
    },
    searchCities() {
      let params = {};
      if(this.selectedCountry) {
        params["countryId"] = this.selectedCountry.id;
      }
      
      // Items have already been requested
      if (this.citiesIsLoading) return;

      this.citiesIsLoading = true;

      ApiService.query("api/cities", { params })
        .then(res => {
          this.userCities = res.data;
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.citiesIsLoading = false));
    },
    parseDate(date) {
      if (date == null) return "";
      return moment
        .utc(date)
        .local()
        .format("yyyy-MM-DD HH:mm:ss");
    },
    changeGender(userProfile) {
      this.$log.debug("userProfile: ", userProfile);
      let changeGenderRequest = {};
      changeGenderRequest["id"] = userProfile.id;
      changeGenderRequest["gender"] = userProfile.gender.value;
      this.loading = true;
      this.genderChanging = true;
      ApiService.put(`api/users/change-gender`, changeGenderRequest)
        .then(response => {
          this.$log.debug("Gender updated: " + response);
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => {
          this.loading = false;
          this.genderChanging = false;
        });
    },
    isMobile() {
      var check = false;
      (function(a) {
        // eslint-disable-next-line
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          // eslint-disable-next-line max-len
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-([a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8])|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-([2-7]|i))|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    },

    goToProfileDialog(id) {
      this.loading = true;
      ApiService.get(`api/users/${id}/user-profile`)
        .then(response => {
          this.$log.debug("UserProfile: ", response.data);
          this.userProfile = response.data;
          this.showProfileDialog = true;
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
    suspendUser(id, suspend) {
      this.loading = true;
      ApiService.patch(`api/users/${id}`, {
        suspended: suspend
      })
        .then(response => {
          this.$log.debug("Suspended: ", response.data.suspended);
          this.info = response.data.suspended;
          this.retrieveUsers();
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
    activateUser(id, activate) {
      this.loading = true;
      ApiService.patch(`api/users/${id}`, {
        activated: activate
      })
        .then(response => {
          this.$log.debug("Activated: ", response.data.activated);
          this.info = response.data.activated;
          this.retrieveUsers();
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
    toggleUserInfluencer(id, influencer) {
      this.loading = true;
      ApiService.patch(`api/users/${id}`, {
        influencer: influencer
      })
        .then(response => {
          this.$log.debug("Influencer: ", response.data.influencer);
          this.info = response.data.influencer;
          this.retrieveUsers();
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
    toggleUserCategory(id, overrideValue) {
      this.loading = true;
      UserApiService.patch(`/admin/${id}/category-override/${overrideValue}`)
        .then(response => {
          this.$log.debug("override value: ", response.data.categoryOverride);
          this.info = response.data.categoryOverride;
          this.retrieveUsers();
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
    changeCategory(id, categoryId) {
      this.loading = true;
      UserApiService.patch(`/admin/${id}/category/${categoryId.id}`)
        .then(response => {
          this.$log.debug("override value: ", response.data.categoryId);
          this.info = response.data.categoryId;
          this.retrieveUsers();
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
    toggleLoyal(id, loyal) {
      this.loading = true;
      UserApiService.patch(`/admin/${id}/loyalty-member/${loyal}`)
        .then(response => {
          this.$log.debug("override value: ", response.data.loyal);
          this.info = response.data.loyal;
          this.retrieveUsers();
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
    toggleVip(id, vip) {
      this.loading = true;
      UserApiService.patch(`/admin/${id}/vip-member/${vip}`)
        .then(response => {
          this.$log.debug("set vip member value: ", response.data.vip);
          this.info = response.data.vip;
          this.retrieveUsers();
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
    toggleOobleeAdmin(id, admin) {
      this.loading = true;
      UserApiService.patch(`/admin/${id}/ooblee-admin/${admin}`)
        .then(response => {
          this.$log.debug("set ooblee admin: ", response.data.oobleeAdmin);
          this.info = response.data.oobleeAdmin;
          this.retrieveUsers();
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
    goToProfile(userId) {
      this.$router.push({ name: "profile", query: { userId: userId } });
    },

    getRequestParams({
      userName,
      category,
      email,
      dateFrom,
      dateTo,
      city,
      userInfluencer,
      userActivated,
      userSuspended,
      shopName,
      userId,
      countryId
    }) {
      let params = {};

      if (userName) {
        params["username"] = userName;
      }

      if (category) {
        params["categoryId"] = category;
      }

      if (email) {
        params["email"] = email;
      }

      if (dateFrom) {
        params["dateFrom"] = dateFrom;
      }

      if (dateTo) {
        params["dateTo"] = dateTo;
      }

      if (userInfluencer) {
        params["userInfluencer"] = userInfluencer;
      }

      if (userActivated) {
        params["userActivated"] = userActivated;
      }

      if (userSuspended) {
        params["userSuspended"] = userSuspended;
      }

      if (city) {
        params["city"] = city;
      }

      if (shopName) {
        params["shopName"] = shopName;
      }

      if (userId) {
        params["userId"] = userId;
      }

      if (countryId) {
        params["countryId"] = countryId;
      }

      let sort;
      let sortBy = this.pagination.sortBy;
      let sortDesc = this.pagination.sortDesc;
      if (sortBy.length === 1 && sortDesc.length === 1) {
        sort = sortBy[0];
        if (sortDesc[0] === true) sort += ",desc";
      }
      params["sort"] = sort;
      params["page"] = this.pagination.page;
      params["size"] = this.pagination.itemsPerPage;

      return params;
    },
    clearSearch() {
      (this.userName = ""),
        (this.category = null),
        (this.email = ""),
        (this.city = ""),
        (this.dateFrom = null),
        (this.dateTo = null),
        (this.userInfluencer = ""),
        (this.userActivated = ""),
        (this.userSuspended = "");
      this.shopName = "";
      this.userId = "";
    },
    retrieveUsers() {
      const params = this.getRequestParams({
        userName: this.userName,
        category: this.category,
        email: this.email,
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        city: this.city,
        userInfluencer: this.userInfluencer,
        userActivated: this.userActivated,
        userSuspended: this.userSuspended,
        shopName: this.shopName,
        userId: this.userId,
        countryId: this.selectedCountry?.id
      });

      return new Promise(resolve => {
        this.loading = true;
        ApiService.query("api/users", {
          params
        }).then(response => {
          // JSON responses are automatically parsed.
          this.$log.debug("Users response: ", response.data);
          this.users = response.data.content;
          this.totalUsers = response.data.totalElements;
          this.totalPages = response.data.totalPages;
          this.loading = false;
          let title = this.totalUsers;
          this.$store.dispatch(SET_BREADCRUMB, [{ title: `${this.name}: ` + title }]);
          resolve();
        });
      });
    },
    getCategories() {
      this.categoriesLoading = true;
      return UserApiService.getCategories()
        .then(res => {
          this.categories = res.data || [];
        })
        .finally(() => {
          this.categoriesLoading = false;
        });
    },
    openChangeRoleModal(user) {
      if (!this.$refs.changeRoleDialog) return;
      this.$refs.changeRoleDialog.open(user);
    }
  }
};
</script>
<style>
.v-data-table.v-data-table.v-data-table >>> td {
  font-size: 16px;
}

.v-icon.v-icon {
  font-size: 24px !important;
}

tbody tr:nth-of-type(even) {
  background-color: rgba(236, 237, 237);
}

tbody tr:nth-of-type(odd) {
  background-color: rgb(250, 250, 250);
}

tbody tr {
  width: 100%;
}

.filter {
  display: flex;
  flex-direction: column;
  padding: 20px;
  justify-content: center;
  background-color: aliceblue;
  border: 1px solid brown;
}

.search {
  display: flex;
  justify-content: space-around;
  padding: 20px;
}

.search-item {
  justify-content: center;
  display: flex;
}

.table-footer-prepend {
  margin-top: -58px;
  height: 58px;
}
</style>
